import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-calcumate',
  templateUrl: './calcumate.component.html',
  styleUrls: ['./calcumate.component.css']
})
export class CalcumateComponent implements OnInit {
  BaseUrl: string;
  constructor(@Inject('BASE_URL') baseUrl: string,private api: ApiService,private meta: Meta, private title: Title) { this.BaseUrl = baseUrl; }

  ngOnInit() {
    this.api.getData().subscribe(innerdata => {

      this.title.setTitle(innerdata['MetaTitle']);
      this.meta.updateTag({ name: 'description', content: innerdata['MetaDescription'] });
      
      return new Promise(resolve => {
        const scriptElementHeader = document.createElement('script');
        // scriptElementHeader.src = "";
        scriptElementHeader.innerHTML = innerdata['seo_header_content'];
        scriptElementHeader.onload = resolve;
        document.head.appendChild(scriptElementHeader);

        const scriptElementFooter = document.createElement('script');
        // scriptElementFooter.src = "";
        scriptElementFooter.innerHTML = innerdata['seo_footer_content'];
        scriptElementFooter.onload = resolve;
        document.body.appendChild(scriptElementFooter);

        const scriptElementSchema = document.createElement('script');
        scriptElementSchema.type = "application/ld+json";
        // scriptElementSchema.src = "";
        scriptElementSchema.innerHTML = innerdata['schema'];
        scriptElementSchema.onload = resolve;
        document.body.appendChild(scriptElementSchema);
      });
    });

    return new Promise(resolve => {
      const scriptElementHeader = document.createElement('script');
      //scriptElementHeader.src = "https://s3.calcumate.co/users/hillsselfstorage/script.js?ref=hillsselfstorage.com.au";
	  scriptElementHeader.src = "https://production.calcumate.co/static/js/main.js";
      scriptElementHeader.id = "calcumate-script";
      scriptElementHeader.onload = resolve;
      document.body.appendChild(scriptElementHeader);

      const scriptChangeQuoteUrl = document.createElement('script');
      scriptChangeQuoteUrl.innerHTML = `$('body').on("click",".tb-calculate-storage-btn",function(){
                                          $("#redirect-url").val("`+this.BaseUrl + `calcumate-get-a-quote");
                                          $(".qtybook").href = "`+ this.BaseUrl + `calcumate-get-a-quote";
                                        });
                                        //$('body').on("click",".qtybook",function(){
										$("body").on("click",".calcumate-cta-button",function(){
                                          /*if($('.tb-local-recommendations .unit-selected').attr('id') != undefined){
                                            localStorage.setItem("SelectedSize",$(".unit-selected").text());
                                          }else{
                                            localStorage.setItem("SelectedSize",$(".highlighted").text());
                                          }*/
										  finalstring="";
										  finalstring =$(".fQSAmU").text()+" ";
										  $(".fQSAmU").next('div').children().each(function () {finalstring = finalstring+($(this).text())+" ";});
										  console.log(finalstring);
										  localStorage.setItem("SelectedSize",finalstring);
                                        });
                                        `;
      scriptChangeQuoteUrl.onload = resolve;
      document.body.appendChild(scriptChangeQuoteUrl);
    });
    
  }
  
}
